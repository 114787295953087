import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentUserPreferenceStore } from './current-user-preference.store';
import {
  PaginationConfig,
  TableColumn,
  defaultPaginationConfig,
  generatePages,
} from '../modals/table.modal';

import { associatedRiskListConfig } from '../../shared/config/associated-risk-list.config';
import { associatedIncidentListConfig } from '../../shared/config/associated-incident-list.config';
import { associatedControlListConfig } from '../../shared/config/associated-control-list.config';
import { associatedComplianceRequirementListConfig } from '../../shared/config/associated-compliance-requirement-list.config';
import { associatedProcessListConfig } from '../../shared/config/associated-process-list.config';
import { AssociatedItemConversion } from '../../shared/conversion/associated-item.conversion';
import { ControlAssociatedItemListDTO, StrategicObjectiveAssociatedItemListDTO } from '../../shared/dto/associated-item.dto';
import { associatedStrategicObjectiveListConfig } from '../../shared/config/associated-strategic-objective.config';
import { associatedSlaListConfig } from '../../shared/config/associated-sla-list.config';

@Injectable({ providedIn: 'root' })
export abstract class AssociatedItemBaseStore {
  isInitialLoading: boolean = true;
  isLoading: boolean = true;
  protected abstract service: any;
  public mainItemId: number;

  riskSearchQuery: string = '';
  controlSearchQuery: string = '';
  incidentSearchQuery: string = '';
  complianceRequirementsSearchQuery: string = '';
  processSearchQuery: string = '';
  strategicObjectiveSearchQuery: string = '';
  slaSearchQuery: string = '';

  strategicObjectives: StrategicObjectiveAssociatedItemListDTO[] = [];
  processes: any[] = [];
  controls: ControlAssociatedItemListDTO[] = [];
  complianceRequirements: any[] = [];
  risks: any[] = [];
  incidents: any[] = [];
  slas: any[] = [];

  strategicObjectiveColumns: TableColumn[] = [];
  processColumns: TableColumn[] = [];
  controlColumns: TableColumn[] = [];
  complianceRequirementColumns: TableColumn[] = [];
  riskColumns: TableColumn[] = [];
  incidentColumns: TableColumn[] = [];
  slaColumns: TableColumn[] = [];

  strategicObjectivePagConf: PaginationConfig = {} as PaginationConfig;
  processPagConf: PaginationConfig = {} as PaginationConfig;
  controlPagConf: PaginationConfig = {} as PaginationConfig;
  complianceRequirementPagConf: PaginationConfig = {} as PaginationConfig;
  riskPagConf: PaginationConfig = {} as PaginationConfig;
  incidentPagConf: PaginationConfig = {} as PaginationConfig;
  slaPagConf: PaginationConfig = {} as PaginationConfig;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private associatedItemConv: AssociatedItemConversion
  ) {
    makeObservable(this, {
      isLoading: observable,
      riskSearchQuery: observable,
      controlSearchQuery: observable,
      incidentSearchQuery: observable,
      complianceRequirementsSearchQuery: observable,
      processSearchQuery: observable,
      strategicObjectiveSearchQuery: observable,
      slaSearchQuery: observable,
      controls: observable,
      risks: observable,
      incidents: observable,
      complianceRequirements: observable,
      processes: observable,
      strategicObjectives: observable,
      slas: observable,
      controlPagConf: observable,
      riskPagConf: observable,
      incidentPagConf: observable,
      complianceRequirementPagConf: observable,
      processPagConf: observable,
      strategicObjectivePagConf: observable,
      slaPagConf: observable,
      loadAssociatedControls: action,
      loadAssociatedRisks: action,
      loadAssociatedIncidents: action,
      loadAssociatedComplianceRequirements: action,
      loadAssociatedProcess: action,
      loadAssociatedStrategicObjectives: action,
      loadAssociatedSlas: action,
    });
  }

  initializeData(
    loadRisks: boolean = false,
    loadIncidents: boolean = false,
    loadControls: boolean = false,
    loadComplianceRequirements: boolean = false,
    loadProcesses: boolean = false,
    loadStrategicObjectives: boolean = false,
    loadSla: boolean = false
  ): void {
    //table fields
    this.strategicObjectiveColumns = associatedStrategicObjectiveListConfig;
    this.processColumns = associatedProcessListConfig;
    this.controlColumns = associatedControlListConfig;
    this.complianceRequirementColumns =
      associatedComplianceRequirementListConfig;
    this.riskColumns = associatedRiskListConfig;
    this.incidentColumns = associatedIncidentListConfig;
    this.slaColumns = associatedSlaListConfig;

    //pagination conf
    this.strategicObjectivePagConf = defaultPaginationConfig;
    this.processPagConf = defaultPaginationConfig;
    this.controlPagConf = defaultPaginationConfig;
    this.complianceRequirementPagConf = defaultPaginationConfig;
    this.riskPagConf = defaultPaginationConfig;
    this.incidentPagConf = defaultPaginationConfig;
    this.slaPagConf = defaultPaginationConfig;

    this.isLoading = true;
    const observables: { [key: string]: Observable<any> } = {};

    if (loadRisks) {
      observables['risks'] = this.loadAssociatedRisks();
    }
    if (loadIncidents) {
      observables['incidents'] = this.loadAssociatedIncidents();
    }
    if (loadControls) {
      observables['controls'] = this.loadAssociatedControls();
    }
    if (loadComplianceRequirements) {
      observables['complianceRequirements'] =
        this.loadAssociatedComplianceRequirements();
    }
    if (loadProcesses) {
      observables['processes'] = this.loadAssociatedProcess();
    }

    if (loadStrategicObjectives) {
      observables['strategicObjectives'] =
        this.loadAssociatedStrategicObjectives();
    }

    if (loadSla) {
      observables['slas'] =
        this.loadAssociatedSlas();
    }

    forkJoin(observables).subscribe({
      next: (results) => {
        runInAction(() => {
          this.isLoading = false;
          this.isInitialLoading = false;
        });
      },
      error: (error) => {
        console.error('Failed to load data:', error);
        this.isLoading = false;
        this.isInitialLoading = false;
      },
    });
  }

  loadAssociatedStrategicObjectives(): Observable<any> {
    return this.service
      .getAssociatedStrategicObjectives(
        this.mainItemId,
        this.strategicObjectivePagConf.currentPage,
        this.strategicObjectivePagConf.pageSize,
        this.strategicObjectiveSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.strategicObjectives =
              this.associatedItemConv.resToStrategicObjectiveAssociatedItemListDTO(
                response.items
              );
            this.strategicObjectivePagConf.totalPages =
              response.pagination.total_pages;
            this.strategicObjectivePagConf.totalCount =
              response.pagination.total_count;
            this.strategicObjectivePagConf.pages = generatePages(
              this.strategicObjectivePagConf.currentPage,
              this.strategicObjectivePagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  loadAssociatedProcess(): Observable<any> {
    return this.service
      .getAssociatedProcesses(
        this.mainItemId,
        this.processPagConf.currentPage,
        this.processPagConf.pageSize,
        this.processSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.processes =
              this.associatedItemConv.resToProcessAssociatedItemListDTO(
                response.items
              );
            this.processPagConf.totalPages = response.pagination.total_pages;
            this.processPagConf.totalCount = response.pagination.total_count;
            this.processPagConf.pages = generatePages(
              this.processPagConf.currentPage,
              this.processPagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  loadAssociatedControls(): Observable<any> {
    return this.service
      .getAssociatedControls(
        this.mainItemId,
        this.controlPagConf.currentPage,
        this.controlPagConf.pageSize,
        this.controlSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.controls =
              this.associatedItemConv.resToControlAssociatedItemListDTO(
                response.items
              );
            this.controlPagConf.totalPages = response.pagination.total_pages;
            this.controlPagConf.totalCount = response.pagination.total_count;
            this.controlPagConf.pages = generatePages(
              this.controlPagConf.currentPage,
              this.controlPagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  loadAssociatedComplianceRequirements(): Observable<any> {
    return this.service
      .getAssociatedComplianceRequirements(
        this.mainItemId,
        this.complianceRequirementPagConf.currentPage,
        this.complianceRequirementPagConf.pageSize,
        this.complianceRequirementsSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.complianceRequirements = this.associatedItemConv.resToTrnComplianceAssociatedItemListDTO(response.items)
            this.complianceRequirementPagConf.totalPages =
              response.pagination.total_pages;
            this.complianceRequirementPagConf.totalCount =
              response.pagination.total_count;
            this.complianceRequirementPagConf.pages = generatePages(
              this.complianceRequirementPagConf.currentPage,
              this.complianceRequirementPagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  loadAssociatedRisks(): Observable<any> {
    return this.service
      .getAssociatedRisks(
        this.mainItemId,
        this.riskPagConf.currentPage,
        this.riskPagConf.pageSize,
        this.riskSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.risks = this.associatedItemConv.resToRiskAssociatedItemListDTO(response.items);
            this.riskPagConf.totalPages = response.pagination.total_pages;
            this.riskPagConf.totalCount = response.pagination.total_count;
            this.riskPagConf.pages = generatePages(
              this.riskPagConf.currentPage,
              this.riskPagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  loadAssociatedIncidents(): Observable<any> {
    return this.service
      .getAssociatedIncidents(
        this.mainItemId,
        this.incidentPagConf.currentPage,
        this.incidentPagConf.pageSize,
        this.incidentSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.incidents = this.associatedItemConv.resToIncidentAssociatedItemListDTO(response.items);
            this.incidentPagConf.totalPages = response.pagination.total_pages;
            this.incidentPagConf.totalCount = response.pagination.total_count;
            this.incidentPagConf.pages = generatePages(
              this.incidentPagConf.currentPage,
              this.incidentPagConf.totalPages
            );
          });
          return response;
        })
      );
  }


  loadAssociatedSlas(): Observable<any> {
    return this.service
      .getAssociatedSlas(
        this.mainItemId,
        this.slaPagConf.currentPage,
        this.slaPagConf.pageSize,
        this.incidentSearchQuery
      )
      .pipe(
        map((response: any) => {
          runInAction(() => {
            this.slas =  this.associatedItemConv.resToSlaAssociatedItemListDTO(
              response.items
            );
            this.slaPagConf.totalPages = response.pagination.total_pages;
            this.slaPagConf.totalCount = response.pagination.total_count;
            this.slaPagConf.pages = generatePages(
              this.slaPagConf.currentPage,
              this.slaPagConf.totalPages
            );
          });
          return response;
        })
      );
  }

  searchInRisks(query: string): void {
    this.riskSearchQuery = query;
    this.loadAssociatedRisks().subscribe();
  }

  searchInIncidents(query: string): void {
    this.incidentSearchQuery = query;
    this.loadAssociatedIncidents().subscribe();
  }

  searchInControls(query: string): void {
    this.controlSearchQuery = query;
    this.loadAssociatedControls().subscribe();
  }

  searchInProcesses(query: string): void {
    this.processSearchQuery = query;
    this.loadAssociatedProcess().subscribe();
  }

  searchInComplianceRequirements(query: string): void {
    this.complianceRequirementsSearchQuery = query;
    this.loadAssociatedComplianceRequirements().subscribe();
  }

  searchInStrategicObjectives(query: string): void {
    this.strategicObjectiveSearchQuery = query;
    this.loadAssociatedStrategicObjectives().subscribe();
  }

  searchInSlas(query: string): void {
    this.slaSearchQuery = query;
    this.loadAssociatedSlas().subscribe();
  }

  resetEntireState() {
    runInAction(() => {
      this.isLoading = true;

      this.strategicObjectiveSearchQuery = '';
      this.processSearchQuery = '';
      this.controlSearchQuery = '';
      this.complianceRequirementsSearchQuery = '';
      this.riskSearchQuery = '';
      this.incidentSearchQuery = '';
      this.slaSearchQuery = '';

      this.strategicObjectiveColumns = [];
      this.processColumns = [];
      this.controlColumns = [];
      this.complianceRequirementColumns = [];
      this.riskColumns = [];
      this.incidentColumns = [];
      this.slaColumns = [];

      this.strategicObjectivePagConf = {} as PaginationConfig;
      this.processPagConf = {} as PaginationConfig;
      this.controlPagConf = {} as PaginationConfig;
      this.complianceRequirementPagConf = {} as PaginationConfig;
      this.riskPagConf = {} as PaginationConfig;
      this.incidentPagConf = {} as PaginationConfig;
      this.slaPagConf = {} as PaginationConfig;

      this.strategicObjectives = [];
      this.processes = [];
      this.controls = [];
      this.complianceRequirements = [];
      this.risks = [];
      this.incidents = [];
      this.slas = [];
    });
  }
}
